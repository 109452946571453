<style lang="sass">textarea {
  width: 100%;
  resize: vertical;
}

.error {
  padding: 0.5em;
}

.controls {
  display: flex;
  justify-content: space-between;
}</style>

<textarea rows="5" bind:value={text}></textarea>
<div class="controls">
    <div>
        <button on:click={submit}>Submit</button>
        <button on:click={onclose}>Close</button>
        {#if context.user}
            Posting as <NameBadge name={["internal", context.user]} />
        {:else}
            <input type="text" placeholder="Name" bind:value={name} />
        {/if}
    </div>
    <div>
        <!-- Technically, this will overestimate text length compared to Python, but ignore that. -->
        <span>{text.length}/{context.maxlen}</span>
    </div>
</div>
{#if lastError}
    <div class="error">{lastError}</div>
{/if}

<script>
    import NameBadge from "./NameBadge.svelte"

    export let onclose
    export let context
    export let id

    let name = ""
    let text = ""
    let lastError

    const submit = async () => {
        try {
            await context.onreplysubmit(id, text, context.user ? null : name)
            onclose()
        } catch(e) {
            lastError = e
        }
    }
</script>