{#if !util.config.deploy}
    <h1>Comments 2™</h1>
    <input bind:value={url} />
    <button on:click={fetchComments}>fetch</button>
{/if}
{#each comments as comment}
    <Comment {...comment} context={context} />
{/each}

{#key unique}
    <ReplyBox {context} id={null} onclose={closeReply} />
{/key}

<script>
    import * as util from "./util"
    import Comment from "./Comment.svelte"
    import ReplyBox from "./ReplyBox.svelte"

    let url = util.config.deploy ? window.location.pathname : "/"
    let comments = []
    let user = null
    let maxlen = null

    $: context = {
        user,
        onreplysubmit,
        maxlen
    }

    const onreplysubmit = async (id, text, name) => {
        console.log(id, text, name)
        const result = await util.apiRequest("post", {
            page: url,
            text,
            parent: id,
            name
        })
        comments = result.comments
        if (result.new_comment) {
            window.location.href = `#com${result.new_comment}`
        }
    }

    const fetchComments = async () => {
        const result = await util.apiRequest("read", url)
        comments = result.comments
        user = result.user
        maxlen = result.max_len
    }

    let unique = {}

    const closeReply = () => {
        unique = {}
    }

    fetchComments()
</script>
