<style lang="sass">.children {
  padding-left: 0.5em;
  border-left: 3px solid black;
}

.comment {
  margin-top: 0.5em;
  margin-bottom: 1.5em;
}
.comment .content {
  padding: 0.25em;
}
.comment .container {
  display: flex;
}
.comment .container .avatar {
  width: 3em;
  height: 3em;
  margin-right: 1em;
}

.comment:last-of-type {
  margin-bottom: none;
}

.controls a {
  text-decoration: none;
  color: #76cd26;
}</style>

<div class={theme ? `comment theme-${theme}` : "comment"}>
    <div class="container">
        <img class="avatar" src={config.avatars + user_id + ".jpg"} alt="user avatar" />
        <div>
            <div>
                <a href={`#com${id}`} title={`#com${id}`}>§</a>
                {#if magnitude > 0}
                    <span style={`transform: rotate(${-angle}rad); display: inline-block`}>→</span>
                    <span>{magnitude.toFixed(1)} /</span>
                {/if}
                Posted by <NameBadge {name} /> at {format(post_dt, "yyyy-MM-dd HH:mm:ss")} ({formatDistance(post_dt, Date.now())} ago)
            </div>
            <div class="content">{@html html}</div>
            <div class="controls">
                <a on:click={replyButton} href="#">Reply</a>
                <span> </span>
                {#each Object.entries(voteSymbols) as [name, label]}
                    <a on:click={e => upvote(name, e)} href="#">{label}</a>
                    <span> </span>
                {/each}
            </div>
            {#if replyOpen}
                <ReplyBox onclose={replyButton} context={context} {id} />
            {/if}
        </div>
    </div>
    <div class="children">
        {#each children as child}
            <svelte:self {...child} context={context} />
        {/each}
    </div>
</div>

<script>
    import { format, formatDistance } from "date-fns"

    import NameBadge from "./NameBadge.svelte"
    import ReplyBox from "./ReplyBox.svelte"
    import { apiRequest, config } from "./util"

    const voteSymbols = {
        "up": "↑",
        "down": "↓",
        "left": "←",
        "right": "→"
    }
    const voteDirections = {
        "up": [0, 1],
        "down": [0, -1],
        "left": [-1, 0],
        "right": [1, 0]
    }

    const computeAngle = votes => {
        const sum = [0, 0]
        for (const [vote, counts] of Object.entries(votes)) {
            if (vote in voteDirections) {
                const dir = voteDirections[vote] 
                sum[0] += dir[0] * counts
                sum[1] += dir[1] * counts
            }
        }
        const magnitude = Math.hypot(...sum)
        const angle = Math.atan2(sum[1], sum[0])
        return [magnitude, angle]
    }

    export let id
    export let html
    export let name
    export let post_time
    export let theme
    export let children
    export let context
    export let user_id
    export let votes

    $: [magnitude, angle] = computeAngle(votes)

    let replyOpen = false

    const replyButton = e => {
        replyOpen = !replyOpen
        if (e) e.preventDefault()
    }

    const upvote = async (voteType, e) => {
        e.preventDefault()
        votes = await apiRequest("vote", id, voteType)
    }

    $: post_dt = new Date(post_time * 1000)
</script>